/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 *
 * Note: The onRenderBody API is not supported in gatsby-browser.js,
 * so we have removed it.
 */

// If you need to run any browser-specific code, you can use a supported API.
// For example, if you want to ensure the body has the correct class (which should
// already be added via SSR), you might use onClientEntry as shown below:

export const onClientEntry = () => {
  const region = process.env.GATSBY_CONTACT_REGION || 'us';
  if (typeof document !== 'undefined') {
    document.body.classList.add(`region-${region}`);
  }
};
